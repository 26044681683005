import { Injectable } from "@angular/core";

/** this service will programmatically download files */
@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  download(fileName: string, content: string) {
    const anchorDownladLink = document.createElement('a');
    anchorDownladLink.setAttribute('href', content);
    anchorDownladLink.setAttribute('download', fileName);
    anchorDownladLink.click();
  }
}
