import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { isNil } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private afAuth: AngularFireAuth) { }

    canActivate(next: ActivatedRouteSnapshot, route: RouterStateSnapshot): Observable<boolean> {
        if (isNil(this.afAuth.currentUser)) {
            return of(true);
        }

        return this.afAuth.authState.pipe(
            take(1),
            tap(user => console.log('[auth-guard: service] user', user)),
            mergeMap(user => {
                if (isNil(user)) {
                    return of(false);
                }

                return user.getIdTokenResult().then(result => result?.claims?.isAdmin);
            }),
            tap(loggedIn => {
                if (!loggedIn) {
                    this.router.navigate(['login']);
                }
            })
        );
    }
}
