import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DownloadService } from './services/download.service';

const firebaseConfig = {
  apiKey: "AIzaSyDEq9yJpMCfY9xzw9PUpyjVbLILbCiOVEU",
  authDomain: "crossfit-timer-817bc.firebaseapp.com",
  projectId: "crossfit-timer-817bc",
  storageBucket: "crossfit-timer-817bc.appspot.com",
  messagingSenderId: "59851725066",
  appId: "1:59851725066:web:0e2b9b7f2083fff1b7c2f3",
  measurementId: "G-LCJFVCSG5E"
}

@NgModule({
  declarations: [	AppComponent ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(firebaseConfig, 'website')
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, DownloadService],
  bootstrap: [AppComponent],
})
export class AppModule {}
